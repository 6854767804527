import { css } from "@emotion/css";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {useSelector, useDispatch} from 'react-redux';
import { logout } from "../actions/auth";

const AppHeader = () => {
  const {isAuth, user} = useSelector(state=> state.auth);
  const dispatch = useDispatch();

  const { push } = useHistory();

  const handleLogout = () => {
    dispatch(logout());
    push("/");
  };

  return (
    <header
      className={
        "header-wrap" +
        " " +
        css`
          .dropdown-menu.show {
            margin-left: -70px;
            /* text-align: center; */
          }

          .dropdown-toggle.btn.btn-success {
            background: transparent;
            border: none;
          }

          .dropdown-toggle.btn.btn-success:hover,
          .dropdown-toggle.btn.btn-success:active {
            background: transparent;
            border: none;
          }
        `
      }
    >
      <div className="container ">
        <div className="header-bottom">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-4">
              <Link to="/" className="logo">
                {/*<img src="https://i.ibb.co/92JF9W1/logo.png" alt="Image" /> */}
                 <img src="https://s3.amazonaws.com/com.onefoodpot/logo.png" alt="Image" /> 
              </Link>
            </div>

            <div className="col-lg-6 col-8 col-md-6">
              <div
                className={
                  "header-bottom-right" +
                  " " +
                  css`
                    @media (min-width: 768px) {
                      display: flex;
                      gap: 1rem;
                    }
                  `
                }
              >
                {!isAuth && (
                  <Link
                    to="/how-does-it-work"
                    className={
                      "btn style1 text-white d-md-block" +
                      " " +
                      css`
                        font-weight: bold;
                        font-size: 0.7rem !important;
                      `
                    }
                  >
                    how does it work
                  </Link>
                )}

                {isAuth && (
                  <div
                    className={
                      "drop-user xl-none" +
                      " " +
                      css`
                        border: none !important;

                        .dropdown-toggle:hover,
                        .dropdown-toggle:active,
                        .dropdown-toggle:focus {
                          box-shadow: none !important;
                        }

                        .dropdown:hover,
                        .dropdown:active,
                        .dropdown:focus,
                        .show.dropdown:hover,
                        .show.dropdown:focus,
                        .show.dropdown:active {
                          border: none !important;
                        }

                        :hover,
                        :focus,
                        :active {
                          border: none !important;
                        }
                      `
                    }
                  >
                    <a href="#" id="trigger">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="success"
                          id="dropdown-basic"
                          className={css`
                            border: none !important;

                            /* show.dropdown:hover,
                            show.dropdown:focus,
                            show.dropdown:active {
                              border: none !important;
                            } */

                            :hover,
                            :focus,
                            :active {
                              border: none !important;
                            }
                          `}
                        >
                          <h5
                            className={css`
                              display: flex;
                            `}
                          >
                            <span>Hii {user.name} !</span>
                            <i
                              className={
                                "fa fa-solid fa-bars" +
                                " " +
                                css`
                                  color: black !important;
                                  font-size: 1rem !important;
                                  padding-left: 0.2rem !important;
                                  margin-bottom: 0.3rem !important;
                                `
                              }
                            ></i>
                          </h5>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item as={Link} to="/profile">
                            My Profile
                          </Dropdown.Item>
                          <Dropdown.Item as={Link} to="/order">
                            My Order
                          </Dropdown.Item>
                          <Dropdown.Item as={Link} to="/how-does-it-work">
                            How does it work
                          </Dropdown.Item>
                          <Dropdown.Item as={Link} to="/c/privacy_policy">
                            App Policy
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={handleLogout}
                            as={Link}
                            to="#"
                          >
                            LogOut
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </a>
                  </div>
                )}
                <div className="main-menu-wrap">
                  <div className="menu-close xl-none">
                    <a href="#">
                      <i className="las la-times"></i>
                    </a>
                  </div>

                  {isAuth && (
                    <div id="menu">
                      <ul className="main-menu list-style">
                        <li className="has-children">
                          <a>
                            <h5 className="person-name">
                              <span>Hii {user.name} !</span>
                              <i
                                className={
                                  "fa fa-solid fa-bars" +
                                  " " +
                                  css`
                                    color: black !important;
                                    font-size: 1rem !important;
                                    padding-left: 0.5rem !important;
                                    margin-bottom: 0.3rem !important;
                                  `
                                }
                              ></i>
                            </h5>
                          </a>
                          <ul
                            className="sub-menu list-style"
                            style={{ display: "none" }}
                          >
                            <li>
                              <Link to="/profile">My Profile</Link>
                            </li>
                            <li>
                              <Link to="/order">My Order</Link>
                            </li>
                            <li>
                              <Link to="/how-does-it-work">
                                How does it work
                              </Link>
                            </li>
                            <li>
                              <Link to="/c/privacy_policy"> App Policy</Link>
                            </li>
                            <li>
                              <a onClick={handleLogout} href="#">
                                Logout
                              </a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
                {!isAuth && (
                  <Link to="/auth" className="login-btn-1">
                    Login<i className="fa fa-solid fa-right-to-bracket"></i>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default AppHeader;
